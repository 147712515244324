import React from 'react'
import { tagPath, categoryPath } from '../../constants'
import { Link } from 'gatsby-plugin-intl'

const Tag = ({ tag, category, selected, language }) => (
  <span className={'tag' + (selected ? ' selected' : '')}>
    <Link
      to={selected ? category[language].url : tagPath(category, tag, language)}
    >
      #{tag}
    </Link>
  </span>
)

const Tags = ({ tags, className, selected, ...rest }) => {
  return tags ? (
    <div className={`tags ${className}`}>
      {tags.map(tag => (
        <Tag key={tag} tag={tag} selected={selected === tag} {...rest} />
      ))}
    </div>
  ) : null
}

export default Tags
