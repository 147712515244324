import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import Tags from './tags'
import { FormattedMessage } from 'gatsby-plugin-intl'

const LatestPost = ({ node }) => {
  const {
    url,
    excerpt,
    frontmatter: {title, translatedTags},
    original: {frontmatter: { date, image}},
  } = node
  return (
    <div className="block columns">
      {image && (
        <div className="column is-one-quarter">
          <Link to={url}>
            <figure className="image">
              <Img fluid={image.childImageSharp.fluid} alt={title} />
            </figure>
          </Link>
        </div>
      )}
      <div className="column">
        <div className="content is-flex is-flex-direction-column">
          <Link className="title is-5" to={url}>
            {title}
          </Link>
          <p>{excerpt}</p>
          {/*<Tags tags={tags} />*/}
        </div>
      </div>
    </div>
  )
}

const LatestPosts = ({ posts }) => (
  <div className="section container">
    <div className="level">
      <div className="level-item has-text-centered-tablet">
        <FormattedMessage id="home.latest">
          {title => <p className="title is-4 is-uppercase">{title}</p>}
        </FormattedMessage>
      </div>
    </div>
    {posts.edges.map(post => (
      <LatestPost key={post.node.id} node={post.node} />
    ))}
  </div>
)

export default LatestPosts
