import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
// eslint-disable-next-line
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import LatestPosts from '../components/latest'
import Navbar from '../components/navbar'
import LandingHero from '../components/landing-hero'

import Topo1 from '../images/topo1.svg'
import Topo2 from '../images/topo2.svg'
import Blob1 from '../images/blob1.svg'

const TopoLink = ({ x, y, circleX, circleY, circleVX = 0}) => (
  <div className="circle-link" style={{ top: `${y}vw`, left: `calc(50% + ${x}vw)` }}>
    <figure className="circle" style={{ top: `${circleY}px`, left: `calc(${circleX}px + (${circleVX}vw))` }} />
    LINK
  </div>
)

const HomePage = props => {
  console.log('home', props)
  const intl = useIntl()
  const t = intl.formatMessage
  const lang = props.pageContext.language
  const heroTitle = t({ id: 'name' })
  const aboutTitle = props.data.home.frontmatter.titles[`title_${lang}`]
  const aboutText = props.data.home.frontmatter.texts[`text_${lang}`].html

  return (
    <Layout location={props.location} noHeader>
      <section className="hero landing-hero is-fullheight">
        <LandingHero {...props.data} />
        <div className="hero-head">
          <header className="site-header">
            <section className="navigation">
              <Navbar />
            </section>
          </header>
        </div>
        <div className="hero-body is-justify-content-flex-start is-flex-direction-column">
          <h1 id="landing-hero-title" className="title is-family-secondary is-uppercase">
            {heroTitle}
          </h1>
        </div>
      </section>
      <section className="section">
        <div className="container article-header has-text-centered">
          <h1 className="title is-3">{aboutTitle}</h1>
        </div>
        <main className="container">
          <div className="content" dangerouslySetInnerHTML={{ __html: aboutText }} />
          <Topo1 id="topo1" />
        </main>
        <div className="container landing-topo">
          <Blob1 id="blob1" />
          <Topo2 id="topo2" />
          <TopoLink x={-40} y={13} circleX={30} circleY={-20}/>
          <TopoLink x={-25} y={26} circleX={0} circleY={-20} circleVX={3.5}/>
        </div>
        <LatestPosts posts={props.data.posts} />
      </section>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage($language: String!) {
    mobileBottomImage: file(relativePath: { eq: "hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1023) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bottomImage: file(relativePath: { eq: "landing-hero-bg.webp" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topImage: file(relativePath: { eq: "hero-bg.webp" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home: markdownRemark(fileAbsolutePath: { glob: "**/_home.page.md" }) {
      frontmatter {
        titles {
          title_en
          title_sk
        }
        texts {
          text_en {
            html
          }
          text_sk {
            html
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: $language } } }
      sort: { fields: original___frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          url
          frontmatter {
            title
            translatedTags
          }
          original {
            frontmatter {
              date(formatString: "L")
              image {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
